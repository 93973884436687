.textarea {
    width: 100%;
    max-height: 58vh;
    padding: .2em .6em;
    border: 1px solid #e3f2fd;
    border-radius: 7px;
    font-size: 15px;
    color: #3c3c3c;
    font-family: Roboto;
    background: transparent;
}

.textarea::placeholder {
    font-family: Roboto;
    /* color: #c6c6c6; */
    color: #b8b8b8;
    font-weight: 300;
    font-size: 14px;
}

.textarea:focus-visible {
    border: 1px solid #067bee;
    outline: none;
}

.utilities {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.right {
    display: flex;
    align-items: center;
    gap: 5px;
}

.tool_btn {
    border: none;
    color: #bfbfbf;
    background: none;
    padding: 0;
}

.remove_btn {
    border: none;
    color: rgb(78, 78, 78);
    background-color: #cfcfcf;
    padding: 3px 6px;
    padding-top: 4px;
    /* font-size: 1.13em; */
    border-radius: 5px;
}

.add_btn {
    border: none;
    color: white;
    background-color: #4197eb;
    padding: 3px 6px;
    padding-top: 4px;
    /* font-size: 1.13em; */
    border-radius: 5px;
}

/* char count */
.charCount {
    margin: 0;
    color: #bfbfbf;
}

.error {
    color: #f13f3f;
}

.img_input {
    display: none;
}