.img_ontainer {
    margin: 5px 0;
    display: flex;
}

.img_wrapper {
    height: 48px;
    position: relative;
    display: inline-block;
    margin-right: 0.8em;
}

.img_wrapper img {
    height: 100%;
    border-radius: 6px;
    box-shadow: 3px 5px 5px 0px #8080805c;
}

.close_icon {
    position: absolute;
    right: -7px;
    top: -7px;
    font-size: 21px;
    color: #1e1e1e;
    background: white;
    border-radius: 100%;
    opacity: 0.7;
    cursor: pointer;
}